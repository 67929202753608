.adContainer {
  border: 1px solid;
  width: 295px;
  margin-left: 20px;
}

.label {
  font-size: 10px;
  text-transform: uppercase;
  margin-bottom: -3px;
  padding-top: 5px;
  padding-left: 10px;
  letter-spacing: 0.3px;
}

.adWrapper {
  margin-bottom: 5px;
}
