
.ad {
  padding: 10px;
  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}
