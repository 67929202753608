.onboarding {
  display: flex;
  justify-content: center;
}

.card {
  display: inline-block;
  width: 450px;

  border: 2px solid;
  padding: 20px;
  margin: 70px 0;

  h1 {
    text-align: center;
    font-size: 20px;
    margin-bottom: 20px;
  }

  a {
    text-align: center;
    font-size: 20px;
    margin: 10px;
    padding: 5px;
    display: block;
  }

  p {
    text-align: center;
  }
}

@media(max-height: 540px) {
  .card {
    margin: 10px 0;
  }
}

@media(max-width: 540px) {
  .card {
    width: 300px;
  }
}
