.width {
  width: 1025px;
  margin: auto;
}

@media(max-width: 1041px) {
  .width {
    width: 100%;
    padding: 0 8px;
  }
}

.body {
  padding-top: 15px;
}
