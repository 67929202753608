$height: 42px;
$height-mobile: 36px;
$link-spacing: 12px;
$link-spacing-mobile: 10px;

.container {
  width: 100%;
  border: 2px solid;
  border-left: 0;
  border-right: 0;
  // position: fixed;
  user-select: none;
  z-index: 10;
}

.content {
  height: $height;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title {
  font-size: 20px;
  display: inline-block;
  border: 1px solid;
  padding: 6px 10px;
  padding-bottom: 0;
}

.links {
  display: inline-flex;
  align-items: center;
}

.link {
  text-decoration: none;
  cursor: pointer;
  padding-right: $link-spacing;
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
}


.link:last-child {
  padding-right: 0;
}

.link:first-child::before {
  content: '';
  padding: 0;
}

.link::before {
  content: '|';
  padding-right: $link-spacing;
}

.user {
  filter: grayscale(45%);
  background-size: cover;
}

.circle {
  $size: 17px;
  cursor: pointer;
  width: $size;
  height: $size;
  margin-left: 7px;
  border: 1px solid;
  border-radius: 20px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 11px;
  padding: 8px;
}

.label {
  font-size: 12px;
}

.unread {
  border: 1px solid;
  padding: 10px;
  font-size: 12px;
}

.unread1 {
  animation: Unread 3s linear infinite;
}

.unread2 {
  animation: Unread 1.5s linear infinite;
}

.unread3 {
  animation: Unread 1.5s linear infinite, Rotate 3s linear infinite;
}

.unread4 {
  animation: Unread 1.5s linear infinite, Rotate 3s linear infinite, Breathe 3s linear infinite;
}

@keyframes Unread {
  0% {
    background-color: #ffff00;
    color: #0000ff;
  }
  50% {
    background-color: #ff0000;
    color: #00ffff;
  }
  100% {
    background-color: #ffff00;
    color: #0000ff;
  }
}

@keyframes Rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes Breathe {
  0% {
    padding: 10px;
  }
  50% {
    padding: 13px;
  }
  100% {
    padding: 10px;
  }
}

.dropdownPosition {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  width: inherit;
  z-index: 1;
  height: 0;
}

.dropdown {
  margin-right: -5px;
  margin-top: 1px;
  width: 320px;
  min-height: 50px;
  max-height: calc(100vh - 48px);
  overflow: scroll;
  border: 2px solid;
  box-sizing: border-box;
  // this is needed because of height:0 on .dropdownPosition
  display: table;
}

.alert {
  margin-bottom: 10px;
  display: flex;
  align-items: center;

  &:last-child {
    margin-bottom: 0;
  }
}

.alertDropdown {
  padding: 5px;
}

.noAlerts {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  height: 50px;
}

.userDropdown li {
  border-bottom: 1px solid;
  padding: 8px;
}
.userDropdown a {
  text-decoration: none
}

.userDropdown li:hover {
  text-decoration: underline;
}

.switchAccounts {
  padding: 5px;
}

.switchAccounts li {
  border: 0;
  cursor: pointer;
}


@media(max-width: 540px) {

  .link::before {
    content: '';
    padding-right: 0;
  }

  .dropdownPosition {
    margin-right: -9px;
  }

  .link {
    padding-right: $link-spacing-mobile / 2;
    padding-left: $link-spacing-mobile / 2;
  }

  .link:last-child {
    padding-right: 0;
  }


}

@media(max-width: 450px) {
  .content {
    height: $height-mobile;
  }

  .title {
    font-size: 16px;
    padding: 3px 6px;
  }
  .link {
    font-size: 13px;
  }

  .circle {
    margin-left: 5px;
  }

  .dropdown {
    top: $height-mobile + 3;
  }
}

@media(max-width: 1041px) {
  .dropdownPosition {
    margin-left: -8px;
  }
  .dropdown {
    margin-right: 0;
  }
}
