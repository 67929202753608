.threadTitle {
  margin-bottom: 15px;
}

.signup {
  text-align: center;
  padding: 20px;
  line-height: 1.3;
}

.disabled {
  filter: blur(1px);
  pointer-events: none;
  user-select: none;
}

.left {
  width: 100%;
}

.back {
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
}

.threadLink {
  text-align: right;
  font-size: 14px;
  padding-bottom: 3px;
}

.addPost {
  margin-top: 10px;
}

.adContainer {
  margin-top: 10px;
}

.ad {
  display: flex;
  justify-content: center;
  margin: 15px;
  margin-left: -10px;
}

.threadContainer {
  display: flex;
  justify-content: space-between;
}

@media(max-width: 540px) {
  .threadContainer {
    display: block;
  }
}
