.container {
  display: flex;
  // justify-content: center;
}

.inner {
  width: 100%;
}

.contentInput {
  width: 100%;
  height: 125px;
  padding: 7px;
  font-size: 15px;
  line-height: 1.2;
}

.submitButton {
  padding: 5px;
  font-size: 13px;
  cursor: pointer;
}
