.header {
  margin-bottom: 25px;

  h4 {
    margin-bottom: 0;
  }
}

.profile {
  margin-bottom: 20px;
}

.intro {
  margin-bottom: 5px;
}

.memberSince {
  margin-bottom: 15px;
}

.sendMessage {
  line-height: 1.2;
  display: inline-block;
  margin-top: 5px;
}

.content {
  display: flex;
}

.left {
  display: inline-block;
}

.right {
  display: inline-block;
  margin-left: 20px;
}

.avatar {
  border: 1px solid;
  border-radius: 3px;
  height: 150px;
  width: 150px;
  margin-bottom: 5px;
  background-size: cover;
  filter: grayscale(45%) contrast(125%);
  display: block;
}

.postContainer {
  margin-bottom: 10px;
  border: 1px solid;
}

.recentPosts {
  h2 {
    margin-bottom: 10px;
    margin-top: 20px;
  }
}

.infoSection {
  margin-bottom: 15px;

  h3 {
    margin-bottom: 2px;
  }
}

.uuid {
  margin-top: 10px;
}

@media (max-width: 540px) {
  .content {
    flex-direction: column;
  }

  .right {
    margin-top: 20px;
    margin-left: 0;
  }

  .left {
    display: flex;
    justify-content: center;
  }

  .avatar {
  height: 230px;
  width: 230px;
  }
}
