.back {
  margin-bottom: 20px;
}

.input {
  padding: 5px;
  font-size: 15px;
  width: 100%;
}

.sectionTitle {
  font-size: 25px;
  margin-bottom: 18px;
}

.label {
  font-size: 17px;
  margin-top: 10px;
  margin-bottom: 5px;
}

.signup {
  text-align: center;
  padding: 20px;
  line-height: 1.3;
}

.disabled {
  filter: blur(1px);
  pointer-events: none;
  user-select: none;
}
