.post {
  border: 1px solid;
  border-bottom: 2px solid;
  margin-bottom: 1px;
}

.avatar {
  border: 1px solid;
  border-radius: 3px;
  height: 60px;
  width: 60px;
  margin-bottom: 5px;
  background-size: cover;
  filter: grayscale(45%) contrast(125%);
}

.header {
  border-bottom: 1px solid;
  font-size: 12px;
  padding: 0.5em;
}

.threadLink {
  font-size: 12px;
  margin-top: 1px;
}

.username {
  text-decoration: underline;
}

.username, .totalPosts {
  font-size: 14px;
  margin-bottom: 4px;
}


.main {
  display: flex;
}

.panel {
  padding: 10px;
  border-right: 1px solid;
  width: 150px;
  text-decoration: none;
}

.panelContainer {
  width: 150px;
  background: no-repeat;
}

.userInfo, .userInfo * {
  background: no-repeat;
}

.content {
  padding: 10px;
  line-height: 1.4;
  word-break: break-word;

  p {
    margin: 13px 0;
  }

  p:first-child {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0
  }
}

.footer {
  width: 100%;
  border-top: 1px solid;
  font-size: 12px;
  padding: 2px;
}

.postedAt {
  display: flex;
  justify-content: space-between;
}

@media(max-width: 520px) {
  .main {
    display: block;
  }

  .content {
    padding: 7px;
  }

  .panel {
    border-right: 0;
    border-bottom: 1px solid;
    width: 100%;
    display: flex;
    padding: 7px;

    .avatar {
      height: 40px;
      width: 40px;
      display: inline-block;
      margin-bottom: 0;
      margin-right: 10px;
    }
  }

  .panelContainer {
    width: 100%;
    display: flex;
    align-items: flex-start;
  }

  .totalPosts {
    font-size: 10px;
  }
}
