.header {
  margin-bottom: 30px;

  h1 {
    font-size: 30px
  }
}

.startThread {
  padding: 2px;
  box-sizing: border-box;
  margin-left: 15px;
}

.startThread:hover {
  border: 1px dotted;
  margin-left: 14px;
}

@media(max-width: 540px) {
  .header {
    margin-bottom: 20px;
  }

  .header h1 {
    font-size: 24px
  }
}

.quickLinks {
  // border: 1px dotted;
  margin-bottom: 15px;
  font-size: 12px;
  strong {
    text-transform: uppercase;
  }
}

.header > * {
  display: inline-block;
  margin-right: 10px;
}

.header > a {
  font-size: 12px;
}

.info {
  font-size: 10px;
  margin-top: 1px;

  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
}

.threadPost {
  margin-bottom: 10px;

}

.postLink {
  display: inline-block;
  min-width: 200px;
}

.forum {
  display: flex;
  justify-content: space-between;
}

.adContainer {
  display: flex;
  justify-content: center;
  margin: 20px 0;
  margin-left: -20px;
  overflow: auto;
}

.left {
  max-width: 700px;
  width: 100%;
}
