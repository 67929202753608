* {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  box-sizing: border-box;

  color: #000;
}

.offWhite {
  background-color: #f6f6f6;
}

.nightmode .offWhite {
  background-color: #000000;
}

.solid {
  background-color: #fff;
}

.nightmode,
.nightmode .solid,
.nightmode input,
.nightmode textarea,
.nightmode button {
  background-color: #000000;
}
.nightmode, .nightmode * {
  color: #ffffff;
}

input, textarea {
  border: 1px solid #737373;
}
.nightmode input,
.nightmode textarea {
  border: 1px solid #ffffff;
}


a {
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
