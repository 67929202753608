.login {
  display: flex;
  justify-content: center;
}

.card {
  display: inline-block;
  width: 250px;

  border: 2px solid;
  padding: 20px;
  margin: 70px 0;

  h1 {
    text-align: center;
    font-size: 20px;
    margin-bottom: 20px;
  }

  input {
    border: 1px solid;
    width: 100%;
    font-size: 16px;
    padding: 5px;
    margin-bottom: 5px;
  }
}

.submitButton {
  padding: 5px;
  font-size: 13px;
  cursor: pointer;
}

@media(max-height: 540px) {
  .card {
    margin: 10px 0;
  }
}
